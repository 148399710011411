import { useCallback, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
  GetPrintifyOrderFunction,
  PrintifyBlueprint,
  PrintifyVariantProvider,
} from '../../clients/fagl-server/types/printify'
import SelectPhotoPage from './SelectPhoto'
import ProductPage from './Product'
import PersonalizePage from './Personalize'
import PreviewPage from './Preview'
import PaymentPage from './Checkout'
import LoadingOverlay from './components/LoadingOverlay'
import ApiClient from '../../ApiClient'
import StripeCheckoutPage from './Checkout/StripeCheckout'
import OrderSuccess from './Checkout/Success'
import Cart from './Checkout/Cart'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import { PreviewUrlState } from './usePrintify'
import useRecordUserAction from '../../hooks/useRecordUserAction'

export default function CreateFlow({
  api,
  openLogsPage,
  blueprint,
  orderState,
  variantProviders,
  createBaseOrder,
  updateQuantity,
  updateQuantityAndVariant,
  loadOrder,
  previewPhotosList,
  isCreatingBaseOrder,
  isGettingOrder,
  openTermsOfUsePage,
  navigateBackToSource,
  isGettingPreview,
  showNotAllLoadedError,
  regeneratePreview,
  isPrintifyPreviewLogsActive,
}: {
  api: ApiClient
  openLogsPage: () => void
  blueprint: PrintifyBlueprint
  orderState: {
    quantity: number
    variantId: number | null
    printProviderId: number | null
  }
  variantProviders: PrintifyVariantProvider[]
  createBaseOrder: (base64: string) => Promise<string | undefined>
  updateQuantity: (quantity: number) => void
  updateQuantityAndVariant: (quantity: number, variantId: number) => void
  loadOrder: GetPrintifyOrderFunction
  previewPhotosList: PreviewUrlState[]
  isCreatingBaseOrder: boolean
  isGettingOrder: boolean
  openTermsOfUsePage: () => void
  navigateBackToSource: () => void
  isGettingPreview: boolean
  showNotAllLoadedError: boolean
  regeneratePreview: () => void
  isPrintifyPreviewLogsActive: boolean
}) {
  const navigate = useNavigate()

  const {
    photoProducts: {
      printify: {
        recordDidTapPersonalize,
        recordDidTapPreview,
        recordDidTapShoppingCart,
        recordDidTapToGetToCheckout,
        recordDidCompleteCheckout,
      },
    },
  } = useRecordUserAction(api)

  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(
    null
  )

  const [confirmedOriginalDataUrl, setConfirmedOriginalDataUrl] = useState<
    string | null
  >(null)
  const [croppedDataUrl, setCroppedDataUrl] = useState<string | null>(null)
  const [candidateDateUrl, setCandidateDataUrl] = useState<string | null>(null)

  const [isCropping, setIsCropping] = useState(false)
  const [chargedAmount, setChargedAmount] = useState<number | null>(null)
  const [chargedCustomerEmail, setChargedCustomerEmail] = useState<
    string | null
  >(null)

  const uniqueColors = new Set<string>()
  const uniqueSizes = new Set<string>()

  variantProviders.forEach((provider) => {
    provider.variants.forEach((variant) => {
      const { color, hexColor, size } = variant.options
      if (color && hexColor) {
        uniqueColors.add(`${color}-${hexColor}`)
      }
      if (size) {
        uniqueSizes.add(size)
      }
    })
  })

  const hasMultipleColors = uniqueColors.size > 1
  const hasMultipleSizes = uniqueSizes.size > 1

  const createPaymentIntent = useCallback(
    async (orderId: string) => {
      const response = await api.printify.createPaymentIntent({
        baseOrderId: orderId,
        quantity: orderState.quantity,
      })
      setClientSecret(response.client_secret)
      setCheckoutSessionId(response.id)
    },
    [api, orderState.quantity]
  )

  const getChargedAmount = useCallback(async () => {
    if (!checkoutSessionId) {
      return
    }
    const response = await api.printify.getPaymentIntent(checkoutSessionId)
    setChargedAmount(response.amount)
    setChargedCustomerEmail(response.email)
    return response
  }, [api, checkoutSessionId])

  const onPhotoSelected = useCallback(
    (base64: string) => {
      setConfirmedOriginalDataUrl(base64)
      setCandidateDataUrl(base64)
      setIsCropping(true)
      navigate('personalize')
    },
    [blueprint.id, navigate, setConfirmedOriginalDataUrl]
  )

  const onPersonalizeCompleted = useCallback(async () => {
    recordDidTapPreview({
      blueprintId: blueprint.id,
    })

    if (!croppedDataUrl) {
      return
    }

    const orderId = await createBaseOrder(croppedDataUrl)
    if (orderId) {
      navigate(`preview/${orderId}`)
    }
  }, [createBaseOrder, navigate, recordDidTapPreview, blueprint.id])

  const onPreviewCompleted = useCallback((orderId: string) => {
    recordDidTapShoppingCart({
      blueprintId: blueprint.id,
    })
    navigate(`payment/${orderId}`)
  }, [])

  const onPaymentCompleted = useCallback(
    async (orderId: string) => {
      recordDidCompleteCheckout({
        blueprintId: blueprint.id,
      })
      await getChargedAmount()
      navigate(`payment/${orderId}/success`)
    },
    [getChargedAmount]
  )

  const onProductSelectionCompleted = useCallback(
    (payload: { quantity: number; variantId: number }) => {
      updateQuantityAndVariant(payload.quantity, payload.variantId)
      recordDidTapPersonalize({
        blueprintId: blueprint.id,
      })
      if (croppedDataUrl) {
        navigate('personalize')
      } else {
        navigate('select-photo')
      }
    },
    [
      navigate,
      updateQuantityAndVariant,
      recordDidTapPersonalize,
      croppedDataUrl,
    ]
  )

  const onCartCompleted = useCallback((orderId: string) => {
    recordDidTapToGetToCheckout({
      blueprintId: blueprint.id,
    })
    navigate(`payment/${orderId}/checkout`)
  }, [])

  const onBackFromCartToPersonalize = useCallback(() => {
    setIsCropping(false)
    navigate('personalize')
  }, [navigate])

  const priceFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter(blueprint.currency),
    [blueprint.currency]
  )

  const navigateFromCartToPersonalize = useCallback(() => {
    setIsCropping(false)
    navigate('personalize')
  }, [navigate])

  const navigateFromCartToProduct = useCallback(() => {
    navigate('.')
  }, [navigate])

  const navigateFromCardToProductsList = useCallback(() => {
    navigateBackToSource()
  }, [navigateBackToSource])

  const formattedPrices = useMemo(() => {
    const { variantId, quantity } = orderState
    const { currency, variants } = blueprint
    const zeroPrice = `${priceFormatter(0)} ${currency}`

    if (!variantId) {
      return {
        price: zeroPrice,
        salePrice: zeroPrice,
        subtotal: zeroPrice,
        chargedAmount: zeroPrice,
      }
    }

    const variant = variants[variantId]
    const price = variant.price
    const salePrice = variant.salePrice
    const subtotal = quantity * (salePrice / 100)
    const formattedChargedAmount = `${priceFormatter(
      chargedAmount ? chargedAmount / 100 : 0
    )} ${currency}`

    return {
      price: `${priceFormatter(price / 100)} ${currency}`,
      salePrice: `${priceFormatter(salePrice / 100)} ${currency}`,
      subtotal: `${priceFormatter(subtotal)} ${currency}`,
      chargedAmount: formattedChargedAmount,
    }
  }, [
    priceFormatter,
    orderState.quantity,
    orderState.variantId,
    chargedAmount,
    blueprint.currency,
    blueprint.variants,
  ])

  const confirmCrop = useCallback(
    (base64url: string) => {
      setCroppedDataUrl(base64url)
      setConfirmedOriginalDataUrl(candidateDateUrl)
      setIsCropping(false)
    },
    [setCroppedDataUrl, candidateDateUrl, setConfirmedOriginalDataUrl]
  )

  const openCropDialog = useCallback(() => {
    setIsCropping(true)
  }, [setIsCropping])

  const closeCropDialog = useCallback(() => {
    setIsCropping(false)
    setCandidateDataUrl(null)
    if (!croppedDataUrl && confirmedOriginalDataUrl) {
      setConfirmedOriginalDataUrl(null)
    }
  }, [setIsCropping, croppedDataUrl, confirmedOriginalDataUrl])

  const onNewPhotoSelected = useCallback(
    async (base64: string) => {
      setCandidateDataUrl(base64)
      setIsCropping(true)
    },
    [onPhotoSelected]
  )

  const cartProducts = [
    {
      title:
        orderState.variantId !== null
          ? blueprint.variants[orderState.variantId].title
          : blueprint.title,
      quantity: orderState.quantity,
      price: formattedPrices.salePrice,
      imageUrl:
        previewPhotosList.find((photo) => photo.isDefault)?.url ||
        previewPhotosList[0]?.url,
    },
  ]

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              initialQuantity={orderState.quantity}
              initialVariantId={orderState.variantId}
              blueprint={blueprint}
              variants={variantProviders[0].variants}
              next={onProductSelectionCompleted}
              previous={navigateBackToSource}
              updateQuantityAndVariant={updateQuantityAndVariant}
            />
          }
        />
        <Route
          path="select-photo"
          element={
            <SelectPhotoPage
              api={api}
              next={onPhotoSelected}
              previous={() => navigate('.')}
            />
          }
        />
        <Route
          path="personalize"
          element={
            <PersonalizePage
              onNewPhotoSelected={onNewPhotoSelected}
              api={api}
              croppedDataUrl={croppedDataUrl}
              isCropping={isCropping}
              aspectRatio={
                orderState.variantId !== null
                  ? blueprint.variants[orderState.variantId].aspectRatio
                  : 1
              }
              candidateDataUrl={candidateDateUrl}
              confirmedOriginalDataUrl={confirmedOriginalDataUrl}
              confirmCrop={confirmCrop}
              openCropDialog={openCropDialog}
              closeCropDialog={closeCropDialog}
              next={onPersonalizeCompleted}
              previous={() => navigate('select-photo')}
            />
          }
        />
        <Route
          path="preview/:orderId"
          element={
            <PreviewPage
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={openLogsPage}
              showNotAllLoadedError={showNotAllLoadedError}
              isOrderLoaded={previewPhotosList.length > 0}
              previewPhotosList={previewPhotosList}
              loadOrder={loadOrder}
              previous={() => navigate('personalize')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
        <Route
          path="payment/:orderId"
          element={
            <PaymentPage
              navigateBackToCart={(orderId: string) =>
                navigate(`payment/${orderId}`)
              }
              isOrderLoaded={previewPhotosList.length > 0}
              isGettingOrder={isGettingOrder}
              loadOrder={loadOrder}
            />
          }
        >
          <Route
            index
            element={
              <Cart
                hasMultipleColors={hasMultipleColors}
                hasMultipleSizes={hasMultipleSizes}
                navigateFromCartToPersonalize={navigateFromCartToPersonalize}
                navigateFromCartToProduct={navigateFromCartToProduct}
                navigateFromCardToProductsList={navigateFromCardToProductsList}
                onQuantityChange={updateQuantity}
                maxQuantity={10}
                next={onCartCompleted}
                previous={onBackFromCartToPersonalize}
                products={cartProducts}
                subtotal={formattedPrices.subtotal}
              />
            }
          />
          <Route
            path="checkout"
            element={
              <StripeCheckoutPage
                createPaymentIntent={createPaymentIntent}
                clientSecret={clientSecret}
                next={onPaymentCompleted}
              />
            }
          />
          <Route
            path="success"
            element={
              <OrderSuccess
                helpEmailSubject="Order Success"
                supportEmailAddress="global-support@family-album.com"
                chargedAmount={formattedPrices.chargedAmount}
                email={chargedCustomerEmail}
              />
            }
          />
        </Route>
      </Routes>
      {(isCreatingBaseOrder || isGettingOrder || isGettingPreview) && (
        <LoadingOverlay opaque animationType="progress-bar">
          Your product preview is being generated. This may take a few moments.
        </LoadingOverlay>
      )}
    </>
  )
}
